import React from 'react';
import styled from 'styled-components';

const StyledFeatures = styled.div`
	padding: 4rem;
	background-image: url(/img/FLR_Our_Rinks_Bottom_Ice.jpg);
	background-color: #adcde4;
	background-position: center bottom;
	background-size: 200% auto;
	background-repeat: no-repeat;
	@media all and (min-width: 1200px) {
		background-size: 100% auto;
	}
	h2 {
		text-align: center;
		color: ${(p) => p.theme.color.red};
		font-size: 1.6rem;
		margin-bottom: 1rem;
	}
	.card-container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		.feat-card {
			padding: 2rem;
			margin: 1rem;
			background-color: #fff;
			width: calc(50% - 2rem);
			h3 {
				font-family: ${(p) => p.theme.fonts.heading};
				font-size: 1.3rem;
				margin-bottom: 1rem;
			}
		}
	}
	@media all and (min-width: 1600px) {
		padding: 5rem 8rem;
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		h3 {
			font-size: 1.6rem !important;
			line-height: 2rem;
		}
		p {
			font-size: 1.3rem;
			line-height: 1.5rem;
		}
	}
	@media all and (max-width: 700px) {
		.card-container {
			flex-direction: column;
			.feat-card {
				width: 100%;
				margin: 1rem 0;
			}
		}
	}
	@media all and (max-width: 500px) {
		padding: 4rem 2rem;
	}
`;

const Features = ({ data }) => {
	return (
		<StyledFeatures>
			<h2>{data.heading}</h2>
			<div className="card-container">
				{data.features.map((item, index) => (
					<div key={index} className="feat-card">
						<h3>{item.heading}</h3>
						<p>{item.copy}</p>
					</div>
				))}
			</div>
		</StyledFeatures>
	);
};

export default Features;
