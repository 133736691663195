import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledServices = styled.div`
	padding: 3rem 3rem 4rem;
	text-align: center;
	h2 {
		color: ${(p) => p.theme.color.red};
		font-size: 1.6rem;
		margin-bottom: 2rem;
	}
	.img-col {
		display: flex;
		justify-content: space-around;
		.col {
			width: 100px;
			display: flex;
			align-items: flex-end;
			margin: 0 auto 1rem;
		}
	}
	.service-col .col .img-container {
		width: 50%;
		margin: 0 auto;
	}
	.service-col {
		display: flex;
		justify-content: space-around;
		margin-bottom: 2rem;
		.col {
			width: 33%;
			padding: 0 2rem;
			@media all and (max-width: 1000px) {
				padding: 0 1rem;
			}
			h3 {
				font-family: ${(p) => p.theme.fonts.heading};
				font-size: 1.3rem;
				margin-bottom: 1rem;
			}
		}
		@media all and (max-width: 768px) {
			flex-direction: column;
			.col {
				width: 60%;
				margin: 0 auto 2rem;
				min-width: 400px;
				.img-container {
					margin-bottom: 1rem;
				}
			}
		}
		@media all and (max-width: 550px) {
			.col {
				min-width: auto;
				width: 100%;
			}
		}
	}
	@media all and (min-width: 1400px) {
		padding: 4rem 10rem 5rem;
	}
	@media all and (min-width: 1600px) {
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		h3 {
			font-size: 1.6rem !important;
			line-height: 2rem;
		}
		p,
		a {
			font-size: 1.3rem;
			line-height: 1.5rem;
		}
	}
`;

const Services = ({ data }) => {
	return (
		<StyledServices>
			<h2>{data.heading}</h2>
			<div className="img-col is-hidden-mobile">
				<div className="col">
					<img
						src="/img/FLR_Our_Rinks_installation.svg"
						alt="installation icon"
					/>
				</div>
				<div className="col">
					<img src="/img/FLR_Our_Rinks_zamboni.svg" alt="maintenance icon" />
				</div>
				<div className="col">
					<img src="/img/FLR_Our_Rinks_storage.svg" alt="storage icon" />
				</div>
			</div>
			<div className="service-col">
				<div className="col">
					<div className="img-container is-hidden-tablet">
						<img
							src="/img/FLR_Our_Rinks_installation.svg"
							alt="installation icon"
						/>
					</div>
					<h3>{data.subHeading1}</h3>
					<p>{data.copy1}</p>
				</div>
				<div className="col">
					<div className="img-container is-hidden-tablet">
						<img src="/img/FLR_Our_Rinks_zamboni.svg" alt="maintenance icon" />
					</div>
					<h3>{data.subHeading2}</h3>
					<p>{data.copy2}</p>
				</div>
				<div className="col">
					<div className="img-container is-hidden-tablet">
						<img src="/img/FLR_Our_Rinks_storage.svg" alt="storage icon" />
					</div>
					<h3>{data.subHeading3}</h3>
					<p>{data.copy3}</p>
				</div>
			</div>
			<Link to="/our-rinks#form" className="redButton">
				GET A FREE QUOTE
			</Link>
		</StyledServices>
	);
};

export default Services;
