import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledForm = styled.div`
	h2 {
		color: ${(p) => p.theme.color.red};
		margin-bottom: 1rem;
		span {
			color: ${(p) => p.theme.color.grey};
		}
	}
	.thanks {
		height: 8rem;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		h2 {
			margin-bottom: 0;
		}
	}
	form {
		padding: 2rem 4rem;
		display: flex;
		.left {
			width: 50%;
			padding-right: 1rem;
			input,
			textarea {
				width: 100%;
				float: left;
				background-color: #dfe1e3;
				padding: 0.75rem;
				margin: 0.5rem 0;
				border: none !important;
				&::placeholder {
					font-weight: 700;
				}
				&#comments {
					height: 170px;
					resize: none;
				}
			}
			label {
				position: absolute !important;
				clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
				clip: rect(1px, 1px, 1px, 1px);
				padding: 0 !important;
				border: 0 !important;
				height: 1px !important;
				width: 1px !important;
				overflow: hidden;
			}
		}
		.right {
			width: 50%;
			padding-left: 1rem;
			label.radio {
				font-weight: 700;
				float: left;
				width: 33%;
				margin: 0.5rem 0;
				text-transform: capitalize;
				span {
					font-weight: 400;
				}
				input {
					margin-right: 10px;
					background-color: red;
				}
			}
			h3 {
				font-family: ${(p) => p.theme.fonts.heading};
				color: ${(p) => p.theme.color.blue};
				margin: 1rem 0 0.5rem;
				&.firstHeading {
					margin: 1.5rem 0 0.5rem;
				}
			}
			hr {
				margin: 0.5rem 0;
				background-color: #dfe1e3;
				width: 95%;
				&.lastLine {
					margin-bottom: 1.5rem;
				}
			}
			button {
				-webkit-appearance: none;
				font-size: 1.1rem;
				padding: 0.25rem 1.5rem;
				border: none;
				cursor: pointer;
			}
		}
	}
	@media all and (min-width: 1400px) {
		form {
			padding: 4rem 6rem;
			.left {
				padding-right: 6rem;
			}
			.right {
				padding-left: 2rem;
			}
		}
	}
	@media all and (min-width: 1600px) {
		form .left {
			padding-right: 10pc;
		}
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		h3 {
			font-size: 1.4rem !important;
			line-height: 2rem;
		}
		label,
		button {
			font-size: 1.1rem;
			line-height: 1.3rem;
		}
		input,
		textarea {
			font-size: 1rem;
		}
	}
	@media all and (max-width: 1050px) {
		form .left {
			width: 42%;
		}
		form {
			.right {
				width: 58%;
			}
			.size {
				display: flex;
				flex-direction: column;
				label.radio {
					width: 100%;
				}
			}
		}
		.design,
		.accessories {
			label {
				width: 50% !important;
			}
		}
	}
	@media all and (max-width: 668px) {
		form {
			flex-direction: column;
			.left {
				width: 100%;
			}
			.right {
				margin-top: 1rem;
				padding: 0;
				width: 100%;
			}
		}
	}
	@media all and (max-width: 500px) {
		form {
			padding: 2rem 3rem;
		}
	}
	@media all and (max-width: 400px) {
		.size,
		.design,
		.accessories {
			label {
				width: 100% !important;
			}
		}
		hr {
			display: none;
		}
		.accessories {
			margin-bottom: 1rem;
		}
	}
`;

const boxClick = () => {
	//put clicked boxes into hidden field
	let design = '';
	if (document.getElementById('refrigeration').checked)
		design = design.concat('refrigeration, ');
	if (document.getElementById('synthetic').checked)
		design = design.concat('synthetic, ');
	if (document.getElementById('pool-conversion').checked)
		design = design.concat('pool conversion, ');
	if (document.getElementById('rental-rink').checked)
		design = design.concat('rental rink, ');
	if (document.getElementById('permanent').checked)
		design = design.concat('permanent, ');
	if (document.getElementById('portable').checked)
		design = design.concat('portable, ');
	//remove last comma
	if (design.substring(design.length - 2) === ', ')
		design = design.substring(0, design.length - 2);

	let accessories = '';
	if (document.getElementById('boards').checked)
		accessories = accessories.concat('boards, ');
	if (document.getElementById('rink-identity').checked)
		accessories = accessories.concat('rink identity, ');
	if (document.getElementById('perimeter-netting').checked)
		accessories = accessories.concat('perimeter netting, ');
	if (document.getElementById('lighting-system').checked)
		accessories = accessories.concat('lighting system, ');
	if (document.getElementById('rubber-matting').checked)
		accessories = accessories.concat('rubber matting, ');
	if (document.getElementById('player-bench').checked)
		accessories = accessories.concat('player bench, ');
	//remove last comma
	if (accessories.substring(accessories.length - 2) === ', ')
		accessories = accessories.substring(0, accessories.length - 2);
	document.getElementById('design').value = design;
	document.getElementById('accessories').value = accessories;
};

const Form = ({ data }) => {
	const [thanks, setThanks] = useState(false);
	const [height, setHeight] = useState(510);

	useEffect(() => {
		if (window.innerWidth < 669) {
			setHeight(document.getElementById('mobileThanksHeight').offsetHeight);
		} else {
			setHeight(document.getElementById('form').offsetHeight);
		}
		setThanks(window.location.search === '?thanks=true');
	});

	return (
		<StyledForm id="form">
			{thanks && (
				<div
					className="thanks"
					id="mobileThanksHeight"
					style={{ height: `${height}px` }}
				>
					<h2>Thanks for submitting.</h2>
					<p>You will hear from us shortly.</p>
				</div>
			)}
			{!thanks && (
				<form
					name="Contact Form"
					method="post"
					data-netlify="true"
					data-netlify-honeypot="bot-field"
					action="/our-rinks/?thanks=true#form"
				>
					<div className="left">
						<input type="hidden" name="form-name" value="Contact Form" />
						<input type="hidden" name="bot-field" />
						<h2>{data.lHeading}</h2>
						<label htmlFor="name">Name</label>
						<input
							type="text"
							id="name"
							name="name"
							placeholder="NAME*"
							required
						/>

						<label htmlFor="email">Email</label>
						<input
							type="email"
							id="email"
							name="email"
							placeholder="EMAIL*"
							required
						/>

						<label htmlFor="city">City</label>
						<input
							type="text"
							id="city"
							name="city"
							placeholder="LOCATION OF RINK (CITY)*"
							required
						/>

						<label htmlFor="phone">Phone</label>
						<input
							type="text"
							id="phone"
							name="phone"
							placeholder="PHONE*"
							required
						/>

						<label htmlFor="comments">Comments</label>
						<textarea
							type="text"
							id="comments"
							name="comments"
							placeholder="ADDITIONAL INFO"
						/>
					</div>
					<div className="right" id="mobileThanksHeight">
						<h2>
							{data.rHeading}
							<span>{data.rHeadingSpan}</span>
						</h2>
						<h3 className="firstHeading">{data.subHeading1}</h3>
						<fieldset className="size">
							<label className="radio is-hidden" htmlFor="size" />
							<label className="radio">
								<input
									type="radio"
									value="peewee"
									name="size"
									id="size-peewee"
								/>
								Peewee <span>20'x40'</span>
							</label>
							<label className="radio">
								<input type="radio" value="pro" name="size" id="size-pro" />
								Pro <span>40'x80'</span>
							</label>
							<label className="radio">
								<input
									type="radio"
									value="custom"
									name="size"
									id="size-custom"
								/>
								Custom <span>Any Size</span>
							</label>
						</fieldset>
						<hr />
						<h3>{data.subHeading2}</h3>
						<fieldset className="design">
							<input
								className="is-hidden"
								defaultValue=""
								id="design"
								name="design"
							/>
							<label className="radio" onClick={boxClick}>
								<input
									type="checkbox"
									value="refrigeration"
									id="refrigeration"
								/>
								refrigeration
							</label>
							<label className="radio" onClick={boxClick}>
								<input type="checkbox" value="synthetic" id="synthetic" />
								synthetic
							</label>
							<label className="radio" onClick={boxClick}>
								<input
									type="checkbox"
									value="pool-conversion"
									id="pool-conversion"
								/>
								pool conversion
							</label>
							<label className="radio" onClick={boxClick}>
								<input type="checkbox" value="rental-rink" id="rental-rink" />
								rental rink
							</label>
							<label className="radio" onClick={boxClick}>
								<input type="checkbox" value="permanent" id="permanent" />
								permanent
							</label>
							<label className="radio" onClick={boxClick}>
								<input type="checkbox" value="portable" id="portable" />
								portable
							</label>
						</fieldset>
						<hr />
						<h3>{data.subHeading3}</h3>
						<fieldset className="accessories">
							<input
								className="is-hidden"
								defaultValue=""
								id="accessories"
								name="accessories"
							/>
							<label className="radio" onClick={boxClick}>
								<input type="checkbox" value="boards" id="boards" />
								boards
							</label>
							<label className="radio" onClick={boxClick}>
								<input
									type="checkbox"
									value="rink-identity"
									id="rink-identity"
								/>
								rink identity
							</label>
							<label className="radio" onClick={boxClick}>
								<input
									type="checkbox"
									value="perimeter-netting"
									id="perimeter-netting"
								/>
								perimeter netting
							</label>
							<label className="radio" onClick={boxClick}>
								<input
									type="checkbox"
									value="lighting-system"
									id="lighting-system"
								/>
								lighting system
							</label>
							<label className="radio" onClick={boxClick}>
								<input
									type="checkbox"
									value="rubber-matting"
									id="rubber-matting"
								/>
								rubber matting
							</label>
							<label className="radio" onClick={boxClick}>
								<input type="checkbox" value="player-bench" id="player-bench" />
								player bench
							</label>
						</fieldset>
						<hr className="lastLine" />
						<button className="redButton" type="submit">
							SUBMIT
						</button>
					</div>
				</form>
			)}
		</StyledForm>
	);
};

export default Form;
