import React from 'react';

import Masthead from '../components/masthead';
import Copy from '../components/copy';
import RinksCTA from '../components/rinks-cta';
import Bar from '../components/edgebar';
import Sizes from '../components/rinks/sizes';
import Services from '../components/rinks/services';
import Features from '../components/rinks/features';
import Form from '../components/rinks/form';

const OurRinksTemplate = ({ copy }) => {
	return (
		<>
			<Masthead title="OUR RINKS" img="/img/FLR_Rinks_Mast.jpg" />
			<Copy text={copy.copy} />
			<Sizes data={copy.sizes} />
			<Bar
				color="red"
				left="auto"
				right="0"
				top="-350px"
				width="auto"
				height="500px"
			/>
			<Services data={copy.services} />
			<Bar
				color="blue"
				left="-5px"
				right="auto"
				top="-20px"
				width="250px"
				height="auto"
			/>
			<Features data={copy.features} />
			<RinksCTA className="rinks" />
			<Form data={copy.form} />
		</>
	);
};

export default OurRinksTemplate;
