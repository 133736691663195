import React from 'react';

import Layout from '../components/layout';
import OurRinksTemplate from '../templates/our-rinks-template';
import SEO from '../components/seo';

const copy = {
	copy:
		'At First Line Rinks, we pride ourselves on a smooth installation process and unrivalled final product that is completed with devotion, professionalism and passion. Your enjoyment is our success.',
	sizes: {
		heading: 'RINK SIZES',
		copy:
			'We specialize in three basic sizes of custom rink, made to suit your specific location and budget.',
	},
	services: {
		heading: 'RINK SERVICES',
		subHeading1: 'RINK INSTALLATION',
		subHeading2: 'MAINTENANCE',
		subHeading3: 'TAKEDOWN & STORAGE',
		copy1:
			'An engineered subfloor system accompanied by our master ice builders allows First Line Rinks to install in any location.',
		copy2:
			"With 3 types of maintenance packages, we're here to make sure your rink is groomed and flooded, ready for family & friends.",
		copy3:
			'Once you are finished skating for the winter, relax! Let First Line Rinks dismantle your rink and keep it safe in our storage facility.',
	},
	features: {
		heading: 'RINK FEATURES',
		features: [
			{
				heading: 'REFRIGERATION RINK SYSTEMS',
				copy:
					'Depending on your ice surface, different sized portable ice chiller systems pump glycol through piping mats laid beneath your skating surface, regulating the ice temperature and enabling skating conditions up to 10°C! We use nothing short of the best chillers and piping system to ensure a steady flow of glycol to the entire ice surface.',
			},
			{
				heading: 'POOL TO RINK CONVERSION',
				copy:
					'If you believe that you do not have the space for a rink in your backyard, chances are First Line Rinks can make it work. If you have an in-ground swimming pool, First Line Rinks has the answer! By constructing an engineered subfloor over the pool, First Line provides its clients a safe and enjoyable winter season, maximizing your backyard all year round.',
			},
			{
				heading: 'RINK RENTALS',
				copy:
					'Contact us to learn more about our inventory of rental rinks. This unique opportunity is open to all customers including large corporations, small businesses and homeowners that just want to have a fun event with a temporary ice rink! First Line Rinks will create and maintain the rink and arrange take-down when your event is over.',
			},
			{
				heading: 'SUB FLOORING',
				copy:
					'The first step in having a great backyard ice rink is a level surface. If there is more than a 3” grade in your backyard, First Line Rinks builds an engineered subfloor system to ensure the highest quality ice surface. As a premier ice rink contractor, our ability to custom build the foundation of your rink means we can install it in virtually any location, meaning almost anyone can enjoy some ice time.',
			},
			{
				heading: 'PRO BOARD CONSTRUCTION',
				copy:
					'Each Board is crafted to ensure that you receive the best quality possible. First Line does not take any short cuts as our boards are backed by a 5 year warranty and easily transported and assembled by our crew. Constructed of pressure treated wood and 3/8″ thick acrylic facing, specially treated for UV protection and warping. All boards come with the required connection hardware.',
			},
			{
				heading: 'CUSTOM RINK IDENTITY',
				copy:
					'Add that professional hockey rink touch, and have your own logo or advertisement pasted anywhere on your boards. With a customized wrapped logo consisting of a thin professionally glued plastic wrap you can make your First Line Rink one to remember. You can also create a logo under the ice. Simply give us the image and leave it to us!',
			},
			{
				heading: 'CUSTOM ACCESSORIES',
				copy:
					'We can give your new ice rink system lots of custom features to make it really special. This includes a custom lighting system for night games, rubber matting on the outer area to protect your skates, perimeter netting to keep the puck from flying, and player benches for when your tournament goes full swing. First Line Rinks wants to make your backyard rink the best it can be.',
			},
			{
				heading: 'SYNTHETIC ICE',
				copy:
					'Imagine the possibilities of skating your own personal rink in the comfort of your basement, or outside in the middle of summer! Our synthetic ice rink system is a great alternative to the real thing; with cutting-edge technology it is manufactured using high quality durable plastic that has a lifetime warranty. The play never has to end!',
			},
		],
	},
	form: {
		lHeading: 'CONTACT INFORMATION',
		rHeading: 'RINK TYPE & FEATURES',
		rHeadingSpan: ' (OPTIONAL)',
		subHeading1: 'RINK SIZE +',
		subHeading2: 'DESIGN +',
		subHeading3: 'ACCESSORIES +',
	},
};

const OurRinksPage = () => (
	<Layout>
		<SEO
			title="Our Rinks"
			description="What sizes of ice rinks do we offer? What features and services are available for your rink? Let’s discuss about your rink options."
		/>
		<OurRinksTemplate copy={copy} />
	</Layout>
);

export default OurRinksPage;
