import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledRinks = styled.div`
	background-image: url(/img/FLR_Our_Rinks_Ice.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	padding: 3rem 10%;
	h2 {
		color: ${(p) => p.theme.color.red};
		font-size: 1.6rem;
		margin-bottom: 1rem;
	}
	.subcopy {
		padding-bottom: 2rem;
	}
	.images {
		display: flex;
		justify-content: center;
		.img-col {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 1rem;
		}
	}
	.text-container {
		display: flex;
		justify-content: center;
		h3 {
			color: ${(p) => p.theme.color.red};
			font-family: ${(p) => p.theme.fonts.heading};
			font-size: 1.3rem;
		}
		p {
			margin: 1rem 0 2rem;
		}
		.bracket-container {
			margin: 1rem auto;
			width: 80%;
			.bracket {
				height: 1rem;
				border-left: 1px solid ${(p) => p.theme.color.grey};
				border-right: 1px solid ${(p) => p.theme.color.grey};
				border-bottom: 1px solid ${(p) => p.theme.color.grey};
				margin: 0 auto;
			}
		}
	}
	.text-col,
	.img-col {
		&.sm {
			width: 25%;
		}
		&.md {
			width: 30%;
			position: relative;
			left: -1rem;
		}
		&.lg {
			width: 35%;
		}
	}
	.sm img,
	.sm .bracket {
		width: 70%;
	}
	.md img,
	.md .bracket {
		width: 88%;
	}
	.lg img,
	.lg .bracket {
		width: 100%;
	}
	@media all and (max-width: 768px) {
		.subcopy {
			max-width: 450px;
			margin: 0 auto;
		}
		.images {
			flex-direction: column;
		}
		.img-col {
			flex-direction: column;
			width: 70% !important;
			margin: 0 auto;
			left: 0 !important;
			.text-container {
				flex-direction: column;
				width: 120%;
			}
			&.sm img {
				width: 50%;
			}
			&.md img {
				width: 75%;
			}
			&.lg img {
				width: 100%;
			}
		}
		&.sm img,
		.sm .bracket {
			width: 50%;
		}
		&.md img,
		.md .bracket {
			width: 75%;
		}
		&.lg img,
		.lg .bracket {
			width: 100%;
		}
	}
	@media all and (min-width: 1600px) {
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
			margin-bottom: 1rem;
		}
		h3 {
			font-size: 1.6rem !important;
			line-height: 2rem;
		}
		p,
		a {
			font-size: 1.3rem;
			line-height: 1.5rem;
		}
	}
	@media all and (max-width: 568px) {
		.img-col {
			width: 90% !important;
		}
	}
`;

const Rinks = ({ data }) => {
	return (
		<StyledRinks>
			<h2>{data.heading}</h2>
			<p className="subcopy">{data.copy}</p>
			<div className="images">
				<div className="img-col sm">
					<img src="/img/FLR_Our_Rinks_Rink1.png" alt="peewee rink" />
					<div className="text-container is-hidden-tablet">
						<div className="bracket-container">
							<div className="bracket" />
						</div>
						<h3>PEEWEE</h3>
						<p>20'x40'</p>
					</div>
				</div>
				<div className="img-col md">
					<img src="/img/FLR_Our_Rinks_Rink2.png" alt="pro rink" />
					<div className="text-container is-hidden-tablet">
						<div className="bracket-container">
							<div className="bracket" />
						</div>
						<h3>PRO</h3>
						<p>40'x80'</p>
					</div>
				</div>
				<div className="img-col lg">
					<img src="/img/FLR_Our_Rinks_Rink3.png" alt="custom rink" />
					<div className="text-container is-hidden-tablet">
						<div className="bracket-container">
							<div className="bracket" />
						</div>
						<h3>CUSTOM</h3>
						<p>ANY SIZE</p>
					</div>
				</div>
			</div>
			<div className="text-container is-hidden-mobile">
				<div className="text-col sm">
					<div className="bracket-container">
						<div className="bracket" />
					</div>
					<h3>PEEWEE</h3>
					<p>20'x40'</p>
				</div>
				<div className="text-col md">
					<div className="bracket-container">
						<div className="bracket" />
					</div>
					<h3>PRO</h3>
					<p>40'x80'</p>
				</div>
				<div className="text-col lg">
					<div className="bracket-container">
						<div className="bracket" />
					</div>
					<h3>CUSTOM</h3>
					<p>ANY SIZE</p>
				</div>
			</div>
			<Link to="/our-rinks#form" className="redButton">
				GET A FREE QUOTE
			</Link>
		</StyledRinks>
	);
};

export default Rinks;
